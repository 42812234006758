<template>
  <AppLayout>
    <div class="flex flex-col mx-auto max-w-7xl w-full md:mt-16 pr-5 md:px-20 px-5">
      <h1>{{ event.title }}</h1>
      <img :src="event.image" alt="Evénement image" class="mx-auto mt-4 md:mt-8 rounded-lg border-2 border-soft-white-20 w-full h-48 md:h-96 object-cover">
      <div class="flex justify-between items-center mt-2 md:mt-4">
        <div class="flex items-center space-x-2">
          <img src="/assets/calendar.svg" alt="calendrier icon">
          <p class="text-sm md:text-xl pt-0 mt-0">{{ event.date }}</p>
        </div>
        <div class="flex items-center space-x-2">
          <img src="/assets/pin-alt.svg" alt="localisation icon">
          <p class="text-sm md:text-xl pt-0 mt-0">Adresse de la party</p>
        </div>
        <div class="flex items-center space-x-2">
          <img src="/assets/money-square.svg" alt="argent icon">
          <p class="text-sm md:text-xl pt-0 mt-0">{{ event.price }}</p>
        </div>
      </div>
      <p class="mt-4 md:mt-8">{{ event.resume }}</p>
    </div>
  </AppLayout>
</template>

<script>
import { events } from '@/data/events.js';  // adjust the path accordingly

export default {
  data() {
    return {
      event: {}
    };
  },
  props: ['eventId'],
  created() {
    this.fetchEventData();
  },
  methods: {
    fetchEventData() {
      // Find the event by its ID from the local data
      this.event = events.find(e => e.id === this.eventId);
    }
  }
}
</script>
