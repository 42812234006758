<template>
  <div>
    <header class="max-w-7xl pr-5 md:px-20 py-5 mx-auto">
      <div class="flex justify-between items-center lg:hidden">
        <svg class="ham hamRotate ham4" viewBox="0 0 100 100" width="70" onclick="this.classList.toggle('active')" @click="toggleMenu">
          <path
              class="line top"
              d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
          <path
              class="line middle"
              d="m 70,50 h -40" />
          <path
              class="line bottom"
              d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
        </svg>
        <router-link to="/">
          <img class="w-20 ml-3" src="../../public/assets/logo.png" alt="">
        </router-link>
        <router-link class="saturated" to="devenirmembre">Devenir <br> membre</router-link>
      </div>
      <div class="hidden lg:flex justify-between items-center">
        <router-link to="/" exact active-class="active-link">
          <img src="../../public/assets/logo.png" alt="Logo association saturation" class="w-32">
        </router-link>
        <router-link to="/evenements" active-class="active-link" class="router-hover text-2xl font-semibold text-soft-white-80">Evénements</router-link>
        <router-link to="/saturation" active-class="active-link" class="router-hover text-2xl font-semibold text-soft-white-80">Qui sommes nous ?</router-link>
        <router-link to="/galerie" active-class="active-link" class="router-hover text-2xl font-semibold text-soft-white-80">Galerie</router-link>
        <router-link to="devenirmembre" active-class="active-link" class="font-semibold text-2xl saturated text-soft-white-80 router-hover">Devenir membre</router-link>
      </div>
    </header>

    <div class="sliding-menu pl-6" id="menu">
      <ul class="space-y-5">
        <li><router-link to="/evenements" active-class="active-link" class="font-semibold text-2xl text-soft-white-80">Evénements</router-link></li>
        <hr class="w-8 border-t-2 border-soft-white-20">
        <li><router-link to="/saturation" active-class="active-link" class="font-semibold text-2xl text-soft-white-80">Qui sommes nous ?</router-link></li>
        <hr class="w-8 border-t-2 border-soft-white-20">
        <li><router-link to="/galerie" active-class="active-link" class="font-semibold text-2xl text-soft-white-80">Galerie</router-link></li>
      </ul>
    </div>

    <!-- Slot for main content -->
    <slot></slot>

    <footer class="mt-16 md:mt-32">
      <hr class="w-full border-t-2 border-soft-white-20">
      <div class="max-w-7xl px-5 md:px-20 items-center py-5 mx-auto space-y-4">
        <img class="w-20 md:w-32 mx-auto" src="../../public/assets/logo.png" alt="">
        <h4 class="text-left md:text-center lg:flex lg:justify-center text-2xl md:text-4xl">Sur le Site</h4>
        <nav class="flex flex-col md:items-center space-y-1 md:space-y-2 text-base md:text-2xl">
          <router-link to="/saturation" active-class="active-link" class="router-hover text-soft-white-80">Qui sommes nous ?</router-link>
          <router-link to="/galerie" active-class="active-link" class="router-hover text-soft-white-80">Galerie</router-link>
          <router-link to="/devenirmembre" active-class="active-link" class="router-hover text-soft-white-80">Devenir membre</router-link>
          <router-link to="/statuts" active-class="active-link" class="router-hover text-soft-white-80">Statuts</router-link>
        </nav>
        <a href="https://www.instagram.com/saturation.ge">
          <img class="mx-auto mt-8 hover:scale-105" src="../../public/assets/instagram.png" alt="logo instagram saturation">
        </a>
        <div class="flex flex-col items-center space-y-2">
          <span>© Saturation 2023 | Tout droit réservé</span>
          <span>Site crée par Luca Frésard & Noé Schmidt</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'AppLayout',
  methods: {
    toggleMenu() {
      const menu = document.getElementById('menu');
      menu.classList.toggle('active');

      if (menu.classList.contains('active')) {
        // Disable scrolling
        document.body.style.overflow = 'hidden';
      } else {
        // Enable scrolling
        document.body.style.overflow = '';
      }    }
  }
}
</script>

<style scoped>
  .active-link {
    color: #FDFAF5;
  }

  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:white;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  .ham4 .top {
    stroke-dasharray: 40 121;
  }
  .ham4 .bottom {
    stroke-dasharray: 40 121;
  }
  .ham4.active .top {
    stroke-dashoffset: -68px;
  }
  .ham4.active .bottom {
    stroke-dashoffset: -68px;
  }

  .sliding-menu {
    position: fixed;
    top: 110px;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    transition: left 0.3s;
    z-index: 1000;
  }

  .sliding-menu.active {
    left: 0;
  }

  .router-hover {
    text-shadow: 0px 0px 0px transparent;
    transition: text-shadow 0.2s ease-in-out;
  }

  .router-hover:hover {
    text-shadow: 3px 3px 0px #C21C1C;
  }

   .saturated {
     position: relative;
     display: inline-block;
     text-align: center;
     text-shadow: 3px 3px 0px #C21C1C;
     line-height: 22px;
     letter-spacing: -0.408px;
     text-decoration: none;
     transition: filter 0.3s ease, text-shadow ease;
     @apply font-semibold
   }

  .saturated:hover {
    text-shadow: 2px 2px 0px #7B0000, 3px 3px 0 #B65151, 4px 4px 0 #C21C1C, 5px 5px 0 #FF000025;
  }

  .saturated:hover::after {
    box-shadow: 2px 2px 0px #7B0000, 3px 3px 0 #B65151, 4px 4px 0 #C21C1C, 5px 5px 0 #FF000025;
  }

</style>
