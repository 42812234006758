import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BecomeMemberView from "@/views/BecomeMemberView";
import StatusView from "@/views/StatusView";
import EventsView from "@/views/EventsView";
import GalleryView from "@/views/GalleryView";
import EventDetail from "@/views/EventDetail";
import WhoAreWeView from "@/views/WhoAreWeView";



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/devenirmembre',
    name: 'Devenir membre',
    component: BecomeMemberView
  },
  {
    path: '/statuts',
    name: 'statuts',
    component: StatusView
  },
  {
    path: '/evenements',
    name: 'Evenements',
    component: EventsView
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: GalleryView
  },
  {
    path: '/saturation',
    name: 'Saturation',
    component: WhoAreWeView
  },
  {
    path: '/event/:eventId',
    name: 'event-detail',
    component: EventDetail,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const menu = document.getElementById('menu');
  if (menu && menu.classList.contains('active')) {
    menu.classList.remove('active');
    document.body.style.overflow = '';
  }
  next();
});

export default router
