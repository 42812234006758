<template>
  <AppLayout>
    <section className="max-w-7xl pr-5 md:px-20 px-5 mx-auto md:mt-16 space-y-4 md:space-y-8">
      <h1>Evénements</h1>
      <h2 class="pt-8 md:pt-12">Evénements à venir</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5">
        <EventCardComponent
            v-for="event in upcomingEvents"
            :key="event.id"
            :eventId="event.id"
            :title="event.title"
            :date="event.date"
            :resume="event.resume"
            :price="event.price"
            :image="event.image"
        />
        <p v-if="upcomingEvents.length === 0">Pas d'événements à venir</p>
      </div>
      <h2 class="pt-8 md:pt-12">Evénements terminés</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5">
        <EventCardComponent
            v-for="event in pastEvents"
            :key="event.id"
            :eventId="event.id"
            :title="event.title"
            :date="event.date"
            :resume="event.resume"
            :price="event.price"
            :image="event.image"
        />
        <p v-if="pastEvents.length === 0">Pas d'événements terminés</p>
      </div>
    </section>
  </AppLayout>
</template>

<script>
import EventCardComponent from "@/components/EventCardComponent.vue";
import {events} from '@/data/events.js';

export default {
  name: 'EventsView',
  components: {EventCardComponent},
  data() {
    return {
      eventsList: events
    };
  },
  computed: {
    upcomingEvents() {
      let currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      return events.filter(event => {
        const eventDate = new Date(event.date.split('-').reverse().join('-'));
        return eventDate >= currentDate;
      }).sort((a, b) => {
        const dateA = new Date(a.date.split('-').reverse().join('-'));
        const dateB = new Date(b.date.split('-').reverse().join('-'));
        return dateA - dateB;
      });
    },
    pastEvents() {
      let currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      return events.filter(event => {
        const eventDate = new Date(event.date.split('-').reverse().join('-'));
        return eventDate < currentDate;
      }).sort((a, b) => {
        const dateA = new Date(a.date.split('-').reverse().join('-'));
        const dateB = new Date(b.date.split('-').reverse().join('-'));
        return dateB - dateA;
      });
    }
  }
}
</script>
