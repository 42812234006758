export const events = [
    //{
    //    id: '1',
    //    title: 'Sample Title 1',
    //    date: '01-09-2023',
    //   resume: 'Sample Resume 1',
    //    price: '$100',
    //    image: '/assets/logo.png'
    //},
];
