<template>
  <AppLayout>
    <img src="/assets/main.jpg" alt="Association saturation" class="w-full h-80 object-cover">
    <div class="flex justify-center">
      <ButtonComponent to="/devenirmembre" class="mt-4 md:mt-8 text-2xl md:text-4xl">Devenir membre</ButtonComponent>
    </div>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto mt-12 md:mt-16 space-y-4 md:space-y-8">
      <h2>Evénements</h2>
      <div v-if="latestEvents.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5">
        <EventCardComponent
            v-for="event in latestEvents"
            :key="event.id"
            :eventId="event.id"
            :title="event.title"
            :date="event.date"
            :resume="event.resume"
            :price="event.price"
            :image="event.image"
        />
      </div>
      <div v-else>
        <p>Pas d'événements prévus pour le moment.</p>
      </div>
      <div class="flex justify-center">
        <ButtonComponent to="/evenements" class="text-2xl md:text-4xl pt-4 md:pt-8">Découvrir les événements</ButtonComponent>
      </div>
    </section>
    <section class="mt-12 md:mt-32 space-y-4 md:space-y-8">
      <h2 class="max-w-7xl pr-5 md:px-20 px-5 mx-auto">Qui sommes-nous ?</h2>
      <div>
        <img src="/assets/qui_sommes_nous.jpg" alt="Association saturation photo de groupe" class="w-full h-52 object-cover">
        <div class="max-w-7xl pr-5 md:px-20 px-5 mx-auto">
          <p>Fondée le 21 août 2021, Saturation est une association genevoise formée de jeunes artistes et techniciens voulant collaborer ensemble pour créer des projets socioculturels. Le collectif est constitué de créateurs vidéoludiques, de scénaristes, de réalisateurs, de musiciens, de producteurs, de chanteurs, de monteurs vidéo, de techniciens de scène, de graphistes et de plasticiens afin d’organiser des événement locaux (vernissages, projections, concerts, etc…). Depuis le 22 Juin 2023, Saturation a rejoint officiellement le Collectif nocturne, pilier majeur du milieu associatif de la nuit genevoise. </p>
        </div>
      </div>
    </section>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto mt-4 md:mt-8 space-y-4 md:space-y-8">
      <div>
        <h3 class="text-2xl md:text-4xl">Nous contacter</h3>
        <p>Pour toute demande, veuillez nous contacter à l’adresse mail suivante : saturation.ge@gmail.com</p>
      </div>
      <div>
        <h3 class="text-2xl md:text-4xl">Nous soutenir</h3>
        <p>Vous pouvez nous soutenir en devenant membre de l'association et en participant aux événements. </p>
      </div>
      <div class="flex justify-center">
        <ButtonComponent to="/devenirmembre" class="pt-4 md:pt-8 text-2xl md:text-4xl">Devenir membre</ButtonComponent>
      </div>
    </section>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto mt-8 md:mt-12 space-y-4 md:space-y-8">
      <h2>Galerie</h2>
      <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
        <Slide v-for="(img, index) in galleryImages" :key="index">
          <img :src="img" alt="Gallery Image" class="carousel__item-img rounded-lg border border-white object-cover w-full h-96 md:h-[30rem]"/>
        </Slide>
      </Carousel>

      <Carousel id="thumbnails" :items-to-show="itemsToShow" :wrap-around="true" v-model="currentSlide">
        <Slide v-for="(img, index) in galleryImages" :key="index">
          <img :src="img" alt="Thumbnail Image" class="carousel__item-img h-40 rounded-lg cursor-pointer w-full px-2 border-white object-cover" @click="slideTo(index)"/>
        </Slide>
      </Carousel>
      <div class="flex justify-center">
        <ButtonComponent to="/galerie" class="mt-4 md:mt-8 text-2xl md:text-4xl">Découvrir toute notre galerie</ButtonComponent>
      </div>
    </section>
  </AppLayout>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import EventCardComponent from '@/components/EventCardComponent.vue';
import { events } from '@/data/events.js';
import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'HomeView',
  components: {
    ButtonComponent,
    EventCardComponent,
    Carousel,
    Slide,
  },
  data: () => ({
    currentSlide: 0,
    galleryImages: [
      '/assets/Gallery_1.jpg',
      '/assets/Gallery_2.jpg',
      '/assets/Gallery_3.jpg',
      '/assets/Gallery_4.jpg',
    ],
    autoScrollInterval: null, // for storing the setInterval reference
  }),
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
    autoScrollGallery() {
      if (this.currentSlide === this.galleryImages.length - 1) {
        this.currentSlide = 0; // Reset to the beginning
      } else {
        this.currentSlide++; // Move to the next slide
      }
    },
  },
  mounted() {
    this.autoScrollInterval = setInterval(this.autoScrollGallery, 3000);
  },
  beforeUnmount() {
    clearInterval(this.autoScrollInterval);
  },
  computed: {
    latestEvents() {
      return events.slice(-2); // Gets the last two events from the events array
    },
    itemsToShow() {
      return window.innerWidth <= 640 ? 1.3 : 4;
    },
  }
}
</script>