<template>
  <AppLayout>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto md:mt-16">
      <h1>Devenir membre</h1>
      <img src="/assets/galerie_1.jpg" alt="devenir membre association saturation" class="h-32 md:h-64 w-full object-cover rounded-lg mt-8 md:mt-12">
      <p>Si vous êtes motivés à devenir membre de l’association, veuillez envoyer votre candidature à l’adresse mail saturation.ge@gmail.com.
        <br>Dans le mail, merci de nous transmettre les informations suivantes :</p>
      <div class="flex space-x-5 mt-4 md:mt-8">
        <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-red rounded-sm mt-1 md:mt-2"></div>
        <span class="w-11/12 "><span class="font-semibold">Vos coordonnées</span> <br><span class="text-soft-white-80">Nom, prénom, âge, numéro de téléphone et adresse email.</span></span>
      </div>
      <div class="flex space-x-5 mt-4 md:mt-8">
        <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-red rounded-sm mt-1 md:mt-2"></div>
        <span class="w-11/12 "><span class="font-semibold">Courte description</span> <br><span class="text-soft-white-80">Vos motivations pour entrer dans l’association, qu’est-ce-que vous apporterez à l’association (en termes de compétences ou de visions).</span></span>
      </div>
      <div class="flex space-x-5 mt-4 md:mt-8">
        <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-red rounded-sm mt-1 md:mt-2"></div>
        <span class="w-11/12 "><span class="font-semibold">Comment avez-vous entendu parler de l’association ?</span> <br><span class="text-soft-white-80">Par vos amis, recherches sur internet, etc...</span></span>
      </div>
      <div class="flex space-x-5 mt-4 md:mt-8">
        <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-red rounded-sm mt-1 md:mt-2"></div>
        <span class="w-11/12 "><span class="font-semibold">Autres transmissions </span> <br><span class="text-soft-white-80">Préférable de nous transmettre un portfolio de vos pratiques d’arts (photos, vidéos, dessins, musiques, etc...) et d’y envoyer par Swisstransfer directement dans le mail.</span></span>
      </div>
      <h2 class="mt-4 md:mt-8">Etape finale</h2>
      <p>Le comité vous contactera afin de fixer un rendez-vous qui déterminera par la suite votre adhésion à l’association.
        <br>Durant cette entrevue, vous pourrez poser vos questions afin d’avoir de plus amples connaissances de l’association.</p>
      <h2 class="mt-4 md:mt-8">Nous contacter</h2>
      <p>Pour toute autre demande, vous pouvez nous contacter à notre adresse mail <a href="mailto:saturation.ge@gmail.com" class="font-semibold underline">saturation.ge@gmail.com</a>.</p>
    </section>
  </AppLayout>
</template>

<script>
export default {
  name: 'BecomeMemberView',
}
</script>
