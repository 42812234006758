<template>
  <router-link class="saturated" :to="link"><slot></slot></router-link>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    link: {
      type: String,
      required: true,
      default: '/'
    }
  }
}
</script>

<style scoped>
.saturated {
  position: relative;
  display: inline-block;
  color: #FDFAF5;
  text-align: center;
  text-shadow: 3px 3px 0px #C21C1C;
  line-height: 22px;
  letter-spacing: -0.408px;
  text-decoration: none;
  transition: filter 0.3s ease, text-shadow ease;
  padding-bottom: 7px;
  @apply font-semibold
}

.saturated::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: currentColor;
  box-shadow: 3px 3px 0px #C21C1C;
}

.saturated:hover {
  text-shadow: 2px 2px 0px #7B0000, 3px 3px 0 #B65151, 4px 4px 0 #C21C1C, 5px 5px 0 #FF000025;
}

.saturated:hover::after {
  box-shadow: 2px 2px 0px #7B0000, 3px 3px 0 #B65151, 4px 4px 0 #C21C1C, 5px 5px 0 #FF000025;
}
</style>

