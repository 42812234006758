export const galleryData = [
    {
        title: "Gallerie",
        images: [
            { src: "/assets/Gallery_1.jpg" },
            { src: "/assets/Gallery_2.jpg" },
            { src: "/assets/Gallery_3.jpg" },
            { src: "/assets/Gallery_4.jpg" },
            { src: "/assets/Gallery_5.jpg" },
            { src: "/assets/Gallery_6.jpg" },
            { src: "/assets/Gallery_7.jpg" },
            { src: "/assets/Gallery_8.jpg" },
            { src: "/assets/Gallery_9.jpg" },
        ]
    },
];
