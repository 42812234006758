<template>
  <AppLayout>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto md:mt-16">
      <h1>Qui sommes nous ?</h1>
      <p class="pt-8 md:pt-12">Fondée le 21 août 2021, Saturation est une association genevoise formée de jeunes artistes et techniciens voulant collaborer ensemble pour créer des projets socioculturels.
        <br> Le collectif est constitué de créateurs vidéoludiques, de scénaristes, de réalisateurs, de musiciens, de producteurs, de chanteurs, de monteurs vidéo, de techniciens de scène, de graphistes et de plasticiens afin d’organiser des événement locaux (vernissages, projections, concerts, etc…).
        <br>Depuis le 22 Juin 2023, Saturation a rejoint officiellement le Collectif nocturne, pilier majeur du milieu associatif de la nuit genevoise. </p>
      <h3 class="text-2xl md:text-4xl mt-8 md:mt-12">Membres du comité</h3>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-8 mt-4 md:mt-8">
        <AdminCardComponent :link="'https://www.instagram.com/tiger_sat01'" :imageSrc="'/assets/adri.jpg'" :name="'Adrien'" :role="'Président'" :age="'24 ans'" :description="'Étudiant dans le domaine du social, Adrien met un point d’honneur à la bonne entente au sein de l’association. Le fait d\'avoir développé une réelle passion pour tout ce qui touche à l’audio-visuel lui donne une large vision afin d’aiguiller les différents membres de Saturation. Fédérateur de nature, il coordonne le comité et encadre les membres de l’association.'" />
        <AdminCardComponent :link="'https://instagram.com'" :imageSrc="'/assets/felix.jpg'" :name="'Felix'" :role="'Vice-président'" :age="'22 ans'" :description="'Mordu de jeux vidéo et de musique depuis enfant, Felix partage sa passion sur Twitch. Toujours prêt à épauler et écouter les membres de l’association, il est un des piliers de Saturation.'" />
        <AdminCardComponent :link="'https://www.instagram.com/rosalesmatheoooo'" :imageSrc="'/assets/matheo.jpg'" :name="'Matheo'" :role="'Actuaire'" :age="'20 ans'" :description="'Jeune monteur vidéo, grand passionné de streaming, Matheo partage sa passion du jeux vidéo sur Twitch. Son métier de gestionnaire du commerce de détail lui procure un avantage pour sa place au sein du comité afin de gérer les comptes et toujours assurer les arrières de l’association.'" />
        <AdminCardComponent :link="'https://www.instagram.com/baby.olivee2.0'" :imageSrc="'/assets/olivia.jpg'" :name="'Olivia'" :role="'Vice-présidente'" :age="'23 ans'" :description="'Passionnée de couture et de dessin, Olivia partage ses connaissances diverses afin d’aiguiller les membres de l’association. Apprentie tapissiere-décoratrice, elle est apte à créer des meubles de ses mains.'" />
        <AdminCardComponent :link="'https://www.instagram.com/cerisesouslegato'" :imageSrc="'/assets/flavio.jpg'" :name="'Flavio'" :role="'Trésorier'" :age="'24 ans'" :description="'Passionné de sport et de réalisation vidéo, Flavio partage ses connaissances sur le monde audiovisuel chez Saturation. Étudiant en HES, il s’occupe des biens financer de l’association.'" />
      </div>
    </section>
  </AppLayout>
</template>

<script>
import AdminCardComponent from '@/components/AdminCardComponent.vue'
export default {
  name: 'WhoAreWeView',
  components: {
    AdminCardComponent
  },
}
</script>