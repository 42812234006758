<template>
  <AppLayout>
    <section class="max-w-7xl pr-5 md:px-20 px-5 mx-auto md:mt-16 space-y-4 md:space-y-8">
      <h1>Galerie</h1>

      <div v-for="event in gallery" :key="event.title">
        <h2 class="pt-6 md:pt-8">{{ event.title }}</h2>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-5 mt-3">
          <img
              v-for="(image, index) in event.images"
              :key="index"
              :src="image.src"
              alt="image galerie"
              class="rounded-lg custom-drop-shadow transition ease-in-out duration-200 hover:cursor-pointer"
              @click="openLightbox(image.src)"
          >
        </div>
        <div class="mt-5 opacity-80">Photos prises par Felix</div>
      </div>
    </section>

    <div v-if="showLightbox" class="lightbox-overlay" @click="closeLightbox">
      <img
          :src="lightboxImage"
          alt="image aggrandie galerie"
          class="lightbox-image enlarged"
      >
    </div>
  </AppLayout>
</template>

<script>
import { galleryData } from '@/data/gallery.js';

export default {
  name: 'GalleryView',
  data() {
    return {
      showLightbox: false,
      lightboxImage: "",
      gallery: galleryData,
      enlarged: true,
    };
  },
  methods: {
    openLightbox(imageSrc) {
      this.lightboxImage = imageSrc;
      this.showLightbox = true;
      document.body.style.overflow = 'hidden';
    },
    closeLightbox() {
      this.showLightbox = false;
      this.lightboxImage = "";
      this.enlarged = false;
      document.body.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped>
img {
  @apply h-28 md:h-48 w-40 md:w-72 object-cover
}
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  @apply w-[80%] h-[20%] md:w-[40%] md:h-[40%];
  border-radius: 0.5rem;
  border-width: thin;
  border-color : #666564;
}

.custom-drop-shadow:hover {
  box-shadow:
      4px 4px 0 2px #7B0000,
      6px 6px 0 2px #B65151,
      8px 8px 0 2px #C21C1C,
      10px 10px 0 2px #FF000025;
}
</style>
