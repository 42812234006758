<template>
  <AppLayout>
    <div class="flex flex-col items-center mx-auto max-w-7xl w-full md:mt-16 pr-5 md:px-20 ">
      <h1>Statuts de l'association</h1>
      <p>Téléchargez nos statuts en PDF en cliquant ci-dessous :</p>
      <a href="/association_status.pdf" download class="download-link hover:text-custom-red mt-2 text-xl">statuts de l'association Saturation</a>
    </div>
  </AppLayout>
</template>

<script>
export default {
  name: 'StatusView',
}
</script>
