<template>
  <router-link :to="`/event/${eventId}`">
    <div class="custom-drop-shadow w-full rounded-lg border border-soft-white-20 flex flex-col transition ease-in-out duration-200 overflow-hidden cursor-pointer h-96">
      <img :src="image" alt="Image événement" class="w-full rounded-t-lg hover:scale-110 transition ease-in-out duration-500" />
      <div class="bg-[#D9D9D9] bg-opacity-10 backdrop-blur-md w-full h-24 rounded-b-lg px-5 py-2">
        <div class="flex justify-between">
          <h6 class="text-soft-white font-semibold md:text-2xl text-stroke">{{ title }}</h6>
          <span class="text-soft-white-80 text-stroke">{{ date }}</span>
        </div>
        <div class="flex justify-between items-center">
          <p>{{ resume }}</p>
          <span class="font-semibold">{{ price }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'EventCardComponent',
  props: {
    title: {
      type: String,
      default: "Title"
    },
    date: {
      type: String,
      default: "Date"
    },
    resume: {
      type: String,
      default: "Resume"
    },
    price: {
      type: String,
      default: "Price"
    },
    image: {
      type: String,
      default: ""
    },
    eventId: {
      type: [String, Number],
      required: true
    }
  },
}
</script>

<style scoped>
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .custom-drop-shadow:hover {
    box-shadow:
    4px 4px 0 2px #7B0000,
    6px 6px 0 2px #B65151,
    8px 8px 0 2px #C21C1C,
    10px 10px 0 2px #FF000025;
  }
  .text-stroke {
    text-shadow:
        -1px -1px 0 #393939,
        1px -1px 0 #393939,
        -1px 1px 0 #393939,
        1px 1px 0 #393939;
  }
</style>