<template>
  <div class="flex space-x-5 w-full">
    <div class="flex-none w-5/12 md:w-4/12 relative rounded-lg">
      <img
          :src="imageSrc"
          alt="image membre du comité"
          class="bg-black rounded-t-lg border border-soft-white-20 object-cover w-full h-40"
      />
      <a :href="link">
        <img class="absolute top-2 right-2 w-10 h-10 bg-soft-black-20 rounded-lg hover:bg-soft-black-80 hover:border hover:border-custom-red" src="../../public/assets/instagram.png" alt="instagram icon" />
      </a>
      <div class="bg-soft-black-20 text-soft-white-80 shadow-inner rounded-b-lg flex flex-col items-center w-full h-30 p-1">
        <h6 class="text-xs md:text-base text-center font-semibold">{{ name }}</h6>
        <span class="text-xs md:text-base">{{ role }}</span>
        <span class="text-xs md:text-base">{{ age }}</span>
      </div>
    </div>
    <div class="flex-grow">
      <p class="text-xs md:text-base">{{ description }}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AdminCardComponent',
  props: {
    link: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    age: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>